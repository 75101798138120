import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { UrlBuilderService } from "../../services/url-builder.service";
import { NgStyle } from "@angular/common";
import { Store } from "@ngrx/store";
import {setAppSumoUpgradeOfferBanner, setTopBanner} from '../../store/platform.actions';
import { RouterLink } from "@angular/router";
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CloseIconSvgComponent } from "../../svg-sprites/general/close-icon-svg.component";


@Component({
  selector: "app-sumo-banner",
  standalone: true,
  imports: [ NgStyle , RouterLink , CloseIconSvgComponent],
  templateUrl: "./app-sumo-banner.component.html",
  styleUrl: "./app-sumo-banner.component.scss",
  animations: [
    trigger('animateNumber', [
      transition(':increment', animate('0.2s', style({ transform: 'translateY(-30px)'}))),
      transition(':decrement', animate('0.2s', style({ transform: 'translateY(-30px)' }))),
      transition('* => *', animate('500ms cubic-bezier(0.68, -0.55, 0.265, 1.55)', ))
    ])
  ]

})
export class AppSumoBannerComponent implements AfterViewInit, OnDestroy {
  days: number | string;
  hours: number | string;
  minutes: number | string;
  seconds: number | string;

  private intervalId: NodeJS.Timeout; // Store the interval ID


  //images
  offerTagImgUrl: string;
  owlImgUrl: string;

  constructor(
    private ub: UrlBuilderService,
    private cdr: ChangeDetectorRef,
    private store: Store
  ) {}

  ngOnInit() {
    // for images
    this.offerTagImgUrl = this.ub.buildImageUrl(
      "images/app-sumo/offer-tag.svg"
    );
    this.owlImgUrl = this.ub.buildImageUrl(
      "images/app-sumo/owl-img.png"
    );
  }

  ngAfterViewInit() {
    console.log("Initializing lifetime deal timer ...");

    // start the timer 5 days from now, or resume where the timer left off
    var nowTimestamp = new Date().getTime();
    var timerDuration = 5 * 24 * 60 * 60 * 1000;
    var defaultEndTimestamp = new Date().getTime() + timerDuration;
    var endTimestamp = defaultEndTimestamp;

    var endCounterStr = localStorage.getItem("APPSUMO_COUNTER");

    if (endCounterStr) {
      endTimestamp = new Date(parseInt(endCounterStr)).getTime();
    }

    if (endTimestamp < nowTimestamp) {
      endTimestamp = defaultEndTimestamp;
      console.log("Timer still counting, will end at: ", endTimestamp);
    }

    localStorage.setItem("APPSUMO_COUNTER", endTimestamp?.toString());

    this.intervalId = setInterval(() => {

      var now = new Date().getTime();
      var distance = endTimestamp - now; // 12h countDownDate - now;

      if (distance < 0) {
        distance = 0;
      }

      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.days = days;
      this.hours = hours < 10 ? "0" + hours : hours;
      this.minutes = minutes < 10 ? "0" + minutes : minutes;
      this.seconds = seconds < 10 ? "0" + seconds : seconds;

      // Trigger change detection explicitly
      this.cdr.detectChanges();

    }, 1000);
  }

  closeBanner() {
    this.store.dispatch(setAppSumoUpgradeOfferBanner({ showAppSumoUpgradeBanner: false }));
  }

  ngOnDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.intervalId);
  }
}
