import {Component, OnInit} from '@angular/core';
import {AppState} from '../store';
import {select, Store} from '@ngrx/store';
import {AffiliateProgramCookie} from '../models/affiliate-program-cookie.model';
import {CookieService} from 'ngx-cookie-service';
import {environment} from '../../environments/environment';
import {TenantService} from '../services/tenant.service';
import {TenantInfo} from '../models/tenant.model';
import {selectTenantInfo, selectUser, selectUserPermissions} from '../store/selectors';
import {combineLatest, noop} from 'rxjs';
import {User} from '../models/user.model';
import {first, withLatestFrom} from 'rxjs/operators';
import {UserPermissions} from '../models/user-permissions.model';
import {increment} from '@firebase/firestore';
import { Firestore, collection, collectionData, doc, docData, query, updateDoc, where, writeBatch } from '@angular/fire/firestore';
import { Auth } from '@angular/fire/auth';

const production = environment.production;

@Component({
  selector: 'affiliate-cookie-tracking',
  template: ``,
  standalone: true,
})
export class AffiliateCookieTrackingComponent implements OnInit {

  milliSecondsInADay = 24 * 60 * 60 * 1000;

  isSecure: boolean = production;

  tenantId: string;

  userId: string;



  constructor(
    private firestore: Firestore,
    private auth: Auth,
    private store: Store<AppState>,
    private cookieService: CookieService,
    private tenant: TenantService
  ) {
  }

  ngOnInit() {

    this.userId = this.auth.currentUser?.uid;

    this.tenantId = this.tenant.id;

    combineLatest([
      this.store.pipe(select(selectTenantInfo)),
      this.store.pipe(select(selectUserPermissions)),
      this.store.pipe(select(selectUser))
    ])
      .subscribe(([tenantInfo, userPermissions, user]) => {
        this.processAffiliateCookie(tenantInfo, userPermissions, user)
          .then(noop);
      });

  }

  async processAffiliateCookie(tenantInfo: TenantInfo, permissions: UserPermissions, user: User) {

    if (!tenantInfo || !tenantInfo.affiliateProgramSettings?.enabled) {
      return;
    }

    await this.saveAffiliateCookie(tenantInfo, permissions, user);

    await this.linkUserToAffiliateReferral(tenantInfo, permissions, user);

  }

  /**
   *
   * This method saves the affiliate cookie, if the affiliateId is present on the url.
   *
   */
  private async saveAffiliateCookie(tenantInfo: TenantInfo, permissions: UserPermissions, currentUser) {

    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    const affiliateId = params.get('affiliateId');
    const type = params.get('type');
    const linkId = type == 'courses-page' ? 'courses-page' : params.get('linkId');

    if (!affiliateId) {
      console.log(`No affiliateId present in the URL, skipping affiliate logic...`);
      return;
    }

    console.log('Affiliate ID present, checking if cookie should be set...');

    const cookieObject: AffiliateProgramCookie = {
      affiliateId,
      createdAt: Date.now()
    }

    const affiliate = await this.getUserByAffiliateId(affiliateId);

    if (!(permissions.isAdmin || permissions.isCourseAdmin)) {

      console.log('User is not admin or course admin, counting link view...');

      await this.countAffiliateLinkView(this.tenantId, affiliateId, linkId, affiliate.id, currentUser);
    }

    const cookieWindow = affiliate?.affiliateSettings?.cookieWindow ?? tenantInfo?.affiliateProgramSettings?.defaultCookieWindow;
    const daysElapsed = this.calculateAffiliateCookieValidity();

    if (daysElapsed && daysElapsed < cookieWindow) {
      console.log('affiliate cookie is still valid, not overwriting.');
      return;
    }

    console.log('Affiliate cookie does not exist or not valid anymore, setting the new affiliate cookie.');

    this.cookieService.delete('OCH_MY_PROGRAM');

    this.setAffiliateProgramCookie(cookieObject, cookieWindow);

    console.log(`Affiliate cookie is set successfully.`);

  }

  setAffiliateProgramCookie(cookieObject: AffiliateProgramCookie, cookieWindow: number) {

    console.log(`Setting cookie OCH_MY_PROGRAM with value ${JSON.stringify(cookieObject)}, expires=${cookieWindow}, secure: ${this.isSecure}, cookieWindow: ${cookieWindow}`);

    const expires = new Date(Date.now() + cookieWindow * this.milliSecondsInADay);

    return this.cookieService.set(
      'OCH_MY_PROGRAM',
      JSON.stringify(cookieObject),
      {
        expires,
        path: '/',
        secure: this.isSecure,
      }
    );
  }

  async countAffiliateLinkView(tenantId: string, affiliateIdInUrl:string, affiliateLinkId: string, userId: string, currentUser: any) {

    const userObject: User = await this.getUserByUserId(currentUser.id);

    if (affiliateIdInUrl === currentUser.affiliateId) {
      console.log(`AffiliateID in Url is same as the AffiliateID of current user, Skipping referral assignment logic... ${affiliateIdInUrl} == ${userObject.affiliateId}`);
      return;
    }

    const batch = writeBatch(this.firestore);
    const statsRef = doc(this.firestore, `schools/${tenantId}/affiliate-links-stats/${affiliateLinkId}/stats/${userId}`);

    const docStatsData = await docData(statsRef, {idField: "id"}).pipe(first()).toPromise();

    if (!docStatsData) {
      batch.set(statsRef, {visitors: 1});
    } else {
      batch.update(statsRef, {visitors: increment(1)}, );
    }
    await batch.commit();

  }


  calculateAffiliateCookieValidity() {

    console.log('Calculating affiliate cookie validity');

    if (!this.cookieService.check('OCH_MY_PROGRAM')) {
      console.log(`Affiliate cookie OCH_MY_PROGRAM not accessible, exiting.`);
      return null;
    }

    const cookieValue = this.cookieService.get('OCH_MY_PROGRAM');

    if (!cookieValue) {
      console.log(`Affiliate cookie OCH_MY_PROGRAM NOT found, skipping affiliate cookie logic...`);
      return;
    }

    console.log(`Affiliate cookie found, value: ${cookieValue}`);

    const cookieObject = JSON.parse(cookieValue);
    const createdAt = cookieObject.createdAt;
    const currentTime = Date.now();

    const timeDifferenceMs = currentTime - createdAt;

    return timeDifferenceMs / this.milliSecondsInADay;
  }


  /**
   * Returns the user affiliate, or null if the affiliate not found for the id.
   * @param affiliateId
   */

  async getUserByAffiliateId(affiliateId:string): Promise<User| null> {

    const constraints = [
      where('affiliateId', '==', affiliateId)
    ]

    const result =  await collectionData(query(collection(this.firestore, `schools/${this.tenantId}/users`), ...constraints), {idField: "id"}).pipe(first()).toPromise()

    const docs = result.map(doc => {
      return {
        id: doc.id,
        ...doc
      } as User;
    });

    return (docs?.length == 1) ? docs[0] : null;

  }

  /**
   *
   * this links in the database the user to a given affiliate.
   *
   */

  private async linkUserToAffiliateReferral(tenant: TenantInfo, permissions: UserPermissions, user: User) {

    if (!user || user?.id == 'anonymous') {
      console.log(`Anonymous user, skipping referral assignment logic...`);
      return;
    }

    if (permissions.isAdmin || permissions.isCourseAdmin) {
      console.log('User is admin or course admin, skipping referral assignment logic...');
      return;
    }

    const cookieValue = this.cookieService.get('OCH_MY_PROGRAM');

    if (!cookieValue) {
      console.log(`Affiliate cookie OCH_MY_PROGRAM NOT found, skipping affiliate cookie logic...`);
      return;
    }

    const cookieObject = JSON.parse(cookieValue);

    const userObject: User = await this.getUserByUserId(user.id);

    if (cookieObject.affiliateId == userObject.affiliateId) {
      console.log(`AffiliateID in cookie is same as the AffiliateID of current user, Skipping referral assignment logic... ${cookieObject.affiliateId} == ${userObject.affiliateId}}`);
      return;
    }

    console.log(`AffiliateID in cookie is different than the AffiliateID of current user, assigning referral... ${cookieObject.affiliateId} != ${userObject.affiliateId}}`);

    return await updateDoc(doc(this.firestore, `schools/${this.tenantId}/users/${user.id}`), {affiliateProgramCookie: cookieObject});
  }

  async getUserByUserId(userId: string): Promise<User> {

    const result = await docData(doc(this.firestore, `schools/${this.tenantId}/users/${userId}`), {idField: "id"}).pipe(first()).toPromise();

    return {
      id: this.userId,
      ...result
    };
  }

}


