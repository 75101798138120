import {Component, inject, OnInit} from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from "@angular/router";
import { delay, filter } from "rxjs/operators";
import { DomSanitizer, Meta } from "@angular/platform-browser";
import { select, Store } from "@ngrx/store";
import { AppState } from "./store";
import { Observable } from "rxjs";
import { platformState, selectTenantInfo } from "./store/selectors";
import { PlatformState } from "./store/platform.reducer";
import { AuthTokenService } from "./services/auth-token.service";
import { LoadingService } from "./shared/services/loading.service";
import { CookieService } from "ngx-cookie-service";
import { TenantService } from "./services/tenant.service";
import { MessagesService } from "./shared/services/messages.service";
import { DEFAULT_FONT_NAME } from "./common/ui-constants";
import { ResourcePreloaderService } from "./services/resource-preloader.service";
import { TopMenuComponent } from "./top-menu/top-menu.component";
import { SourceTrackingComponent } from "./source-tracking/source.tracking.component";
import { RewardfulIntegrationComponent } from "./rewardful-integration/rewardful-integration.component";
import { InstructorChatComponent } from "./instructor-chat/instructor-chat.component";
import { PaypalIntegrationComponent } from "./paypal-integration/paypal-integration.component";
import { FacebookIntegrationComponent } from "./facebook-integration/facebook-integration.component";
import { GoogleAnalyticsIntegrationComponent } from "./google-analytics-integration/google-analytics-integration.component";
import { ThirdPartyScriptsInjectorComponent } from "./third-party-scripts-injector/third-party-scripts-injector.component";
import { NgIf, AsyncPipe } from "@angular/common";
import { MessagesComponent } from "./shared/messages/messages.component";
import { LoadingComponent } from "./shared/loading/loading.component";
import { AiGlobalChatComponent } from "./ai-global-chat/ai-global-chat.component";

import { TopDiscountBannerComponent } from "./shared/top-discount-banner/top-discount-banner.component";
import { MixPanelIntegrationComponent } from "./mix-panel-integration/mix-panel-integration.component";

import { AffiliateCookieTrackingComponent } from "./affiliate-cookie-tracking/affiliate-cookie-tracking.component";
import { environment } from "../environments/environment";

import { AppSumoBannerComponent } from "./shared/app-sumo-banner/app-sumo-banner.component";
import {AiGlobalChatService} from './ai-global-chat/ai-global-chat.service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [CookieService],
  standalone: true,
  imports: [
    NgIf,
    ThirdPartyScriptsInjectorComponent,
    GoogleAnalyticsIntegrationComponent,
    FacebookIntegrationComponent,
    PaypalIntegrationComponent,
    InstructorChatComponent,
    RewardfulIntegrationComponent,
    SourceTrackingComponent,
    TopMenuComponent,
    RouterOutlet,
    AsyncPipe,
    MessagesComponent,
    LoadingComponent,
    AiGlobalChatComponent,
    TopDiscountBannerComponent,
    MixPanelIntegrationComponent,
    AffiliateCookieTrackingComponent,
    AppSumoBannerComponent,
  ],
})
export class AppComponent implements OnInit {
  isPlatformLogin: boolean;

  settings$: Observable<PlatformState>;

  aiChatService = inject(AiGlobalChatService);

  isAiChatVisible$ = this.aiChatService.showGlobalAiChat$;

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private store: Store<AppState>,
    private loading: LoadingService,
    private route: ActivatedRoute,
    private token: AuthTokenService /* don't remove - this ensures the token is ready for the auth interceptor */,
    private cookies: CookieService,
    private tenant: TenantService,
    private messages: MessagesService,
    private meta: Meta,
    private preloader: ResourcePreloaderService /* don't remove - this ensures certain static resources are correctly pre-loaded */
  ) {}

  ngOnInit() {

    console.log("Bootstrapping frontend application  ......");

    this.isPlatformLogin = document.location.href?.includes("platform-login");

    this.loading.showLoading();

    this.settings$ = this.store.pipe(
      select(platformState),
      filter(
        (platformSettings) =>
          platformSettings.brandTheme &&
          !!platformSettings.brandTheme.primaryColor
      ),
      delay(0)
    );

    this.route.queryParamMap
      .pipe(filter((params) => !!params.get("authJwtToken")))
      .subscribe((params) => {
        const authJwtToken = params.get("authJwtToken");
        const accountCreationEvent = !!params.get("accountCreationEvent");
        const platformPlanPurchasedEvent = !!params.get(
          "platformPlanPurchasedEvent"
        );

        // clear the JWT from the url, to make sure the token is never accidentally reused
        // but if there is an account creation event, this needs to be reported
        if (
          authJwtToken &&
          !accountCreationEvent &&
          !platformPlanPurchasedEvent
        ) {
          // wait just a bit before clearing the url, in case other components need url parameters at startup time
          setTimeout(() => {
            window.history.replaceState(null, null, window.location.pathname);
          }, 2000);
        }
      });

    this.store
      .pipe(select(selectTenantInfo))
      .pipe(
        filter(
          (tenant) => tenant?.customDomainConfig?.currentStep == "completed"
        )
      )
      .subscribe((tenant) => {
        this.setCanonicalUrl(this.tenant?.tenant?.customDomain);
      });
  }

  /**
   *
   * This style tag gets injected in the page, and it will overwrite the default theme with the tenant brand colors.
   *
   */

  brandStyles(branding: PlatformState) {
    const primaryColor = branding.brandTheme.primaryColor,
      accentColor = branding.brandTheme.accentColor;

    const sliderColor = `rgba(${parseInt(
      primaryColor.substring(1, 3),
      16
    )},${parseInt(primaryColor.substring(3, 5), 16)},${parseInt(
      primaryColor.substring(5, 7),
      16
    )},0.5)`;

    const brandTheme = this.tenant?.tenant?.brandTheme;

    const websiteFontName = brandTheme?.customFont?.name ?? DEFAULT_FONT_NAME;

    const customCss = brandTheme?.customCss;

    let customStyles = `

    <link href="https://fonts.googleapis.com/icon?family=Material+Icons&cache-busting=599510" rel="stylesheet">

    `;

    if (brandTheme?.customFont?.url) {
      customStyles += `<link href="${brandTheme?.customFont?.url}" rel="stylesheet">`;
    }

    // These styles will override the default Angular Material theme
    // This could be done either to apply the course creator brand to the website, or to override the defaults of Angular Material
    customStyles += `

      <style>

        /* global font */
        h1, h2, h3, h3, h5, h6, span:not(.material-symbols-outlined):not(.material-icons) , div, ul, li, p {
          font-family: ${websiteFontName}, sans-serif !important;
        }

        /* top menu */
        .theme .navbar {
            background: ${primaryColor};
        }
        .theme .menu-item-link.active::after {
          background: ${accentColor};
        }

        .theme .mat-mdc-menu-item .mat-icon-no-color, .mat-mdc-menu-submenu-icon {
            color: #0000008a;
         }

        /* custom-buttons  */
        .theme .btn-primary{
          background: ${primaryColor};
        }
        .theme .btn-accent{
          background: ${accentColor};
        }
        .theme .btn-admin-accent-active{
          background: ${accentColor};
          border:1px solid ${accentColor};
        }
        .theme .btn-admin-primary-active{
          background: ${primaryColor};
          border:1px solid ${primaryColor};
        }
        /* custom-buttons-end  */

        /* buttons  */
        .theme .mat-mdc-raised-button.mat-primary:not([disabled]),
        .theme .mat-mdc-fab.mat-primary:not([disabled]),
        .theme .mat-mdc-mini-fab.mat-primary:not([disabled]) {
            background-color: ${primaryColor};
        }

        .theme .mat-mdc-raised-button.mat-primary,
        .theme .mat-mdc-fab.mat-primary,
        .theme .mat-mdc-mini-fab.mat-primary,
        .theme .mat-mdc-raised-button.mat-accent,
        .theme .mat-mdc-fab.mat-accent,
        .theme .mat-mdc-mini-fab.mat-accent {
            --mdc-protected-button-label-text-color: white;
        }

        .theme .mat-button.mat-primary, .theme .mat-icon-button.mat-primary, .theme .mat-stroked-button.mat-primary {
            color: ${primaryColor};
        }

        /* slide toggle */
        .theme .mat-mdc-slide-toggle.mat-primary {
            --mdc-switch-selected-focus-state-layer-color: ${primaryColor};
            --mdc-switch-selected-handle-color: ${primaryColor};
            --mdc-switch-selected-hover-state-layer-color: ${primaryColor};
            --mdc-switch-selected-pressed-state-layer-color: ${primaryColor};
            --mdc-switch-selected-focus-handle-color: ${primaryColor};
            --mdc-switch-selected-hover-handle-color: ${primaryColor};
            --mdc-switch-selected-pressed-handle-color: ${sliderColor};
            --mdc-switch-selected-focus-track-color: ${sliderColor};
            --mdc-switch-selected-hover-track-color: ${sliderColor};
            --mdc-switch-selected-pressed-track-color: ${sliderColor};
            --mdc-switch-selected-track-color: ${sliderColor};
        }

        /* progress spinner */
        .theme .mat-mdc-progress-spinner circle, .mat-mdc-spinner circle {
            stroke: ${primaryColor};
        }

        /* progress bar */
        .theme .mat-mdc-progress-bar {
            --mdc-linear-progress-active-indicator-color: ${primaryColor};
        }

        /* regular input fields */
        .theme .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--filled {
            max-height: 50px;
        }

        .theme .mdc-text-field--filled:not(.mdc-text-field--disabled) {
            color: rgba(0, 0, 0, 0.87);
            background-color: #fafafa;
         }

         .theme .mat-mdc-input-element {
            font-weight: 300;
         }

         .theme .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
            padding-top: 10px;
            padding-bottom: 10px;
         }

        /* outline input fields */

        .theme .mat-mdc-form-field-infix {
            min-height: 40px;
        }

        .theme .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
            padding-top: 8px;
            padding-bottom: 8px;
        }

        .theme .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--outlined {
          font-size: 14px;
        }

        .theme .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
            top: 20px;
        }

        .theme .mat-mdc-form-field-hint {
            font-size: 10px;
        }

        /* tabs */
        .theme .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
            color: black;
            font-weight: 700;
        }

        /* cards */
        .theme .mat-mdc-card {
            color: #000000de;
        }

        .theme .mat-mdc-card-content {
            font-size: 14px;
        }

        .theme .mat-mdc-card-header .mat-mdc-card-header-text .mat-mdc-card-title {
            font-size: 20px;
            font-weight: 500;
            text-align: center;
            line-height: 25px;
            margin-bottom: 10px;
        }

        .theme .mat-mdc-dialog-container {
            background-color: #fafafa;
        }

        ${customCss ? customCss : ""}

      </style>`;

    return this.sanitizer.bypassSecurityTrustHtml(customStyles);
  }

  /**
   *
   * point this page to the original domain, so that the search engines don't index the OCH subdomain
   *
   */

  private setCanonicalUrl(customDomain: string) {
    const host = window.location.host;
    // if we are on boostrand.onlinecoursehost.com for example, meaning that it's a tenant subdomain, point to the custom domain as the original source
    if (host?.includes(environment.domainName)) {
      const link = document.createElement("link");
      link.rel = "canonical";
      link.href = `https://${customDomain}${document.location.pathname}`;
      document.head.appendChild(link);
    } else {
      console.log(
        `Host ${host} does not include ${environment.domainName} - not setting canonical url`
      );
    }
  }
}
