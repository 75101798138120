<top-menu-svg-sprite />

<magic-wand-icon-svg />

<ng-container *ngIf="(data$ | async) as data">

  <div class="navbar" *ngIf="!isFullScreenMode" (click)="closeAiGlobalChat()">
    <div class="navbar-wrapper ">
      <div class="navbar-brand" *ngIf="isPlatformSite">
        <a target="_blank" href="https://onlinecoursehost.com">
          <img class="hat-logo" src="https://d8avu785lzlph.cloudfront.net/images/logos/och-hat-v1.svg" />
        </a>
      </div>
      <button class="toggle-menu" *ngIf="!isPlatformSite && !isTenantLoginPage" (click)="toggleMenu()">
        <span class="material-symbols-outlined">
          {{isOpen ? 'close' : 'menu'}}
        </span>
      </button>

      <div class="navbar-menu" [ngClass]="{'open': isOpen}" #navBarLeftMenu>
        <ng-container *ngIf="!isSingleSignOnPage && !isPlatformSite">
          <ng-container *ngIf="!isLandingPageMode">
            <ul class="menu">

              @if (data?.tenant?.topMenuLogoConfig?.topMenuLogoUrl) {
              <li class="menu-item app-logo" routerLink="/home">
                <!-- @if (data?.tenant?.homePageOn) { -->
                <img class="logo" [src]="data?.tenant?.topMenuLogoConfig?.topMenuLogoUrl" />
                <!-- } -->
              </li>
              }

              <li class="menu-item" *ngIf="data?.tenant?.homePageOn" (click)="toggleMenu()">
                <div class="menu-item-icon">
                  <span class="material-symbols-outlined">
                    home
                  </span>
                </div>

                @if(!homePageAvailable) {

                <a class="menu-item-link" routerLink="/home" routerLinkActive="active"
                  *ngIf="data?.tenant?.homePageOn">{{ "menu.home" | translate }}</a>

                } @else {

                <a routerLink="/pages/{{homePageAvailable.url}}" class="menu-item-link" routerLinkActive="active"
                  *ngIf="data?.tenant?.homePageOn">{{ "menu.home" | translate }}</a>
                }

              </li>

              <li class="menu-item" (click)="toggleMenu()">
                <div class="menu-item-icon">
                  <span class="material-symbols-outlined">
                    sticky_note_2
                  </span>
                </div>
                <a class="menu-item-link" routerLink="/courses" routerLinkActive="active">{{ "menu.courses" |
                  translate }}
                </a>
              </li>

              @if ((isCommunityVisible(data))) {
              <li class="menu-item" (click)="toggleMenu()">
                <div class="menu-item-icon">

                  <span class="material-symbols-outlined">
                    sticky_note_2
                  </span>
                </div>
                <a class="menu-item-link" routerLink="/community" routerLinkActive="active">{{
                  "community.common.community" |
                  translate }}
                </a>
              </li>
              }

              <li class="menu-item" *ngIf="!data?.tenant?.allLessonsTabOff" (click)="toggleMenu()">
                <div class="menu-item-icon">
                  <span class="material-symbols-outlined">
                    smart_display
                  </span>
                </div>
                <a class="menu-item-link" routerLink="/all-lessons" routerLinkActive="active">{{ "menu.allLessons" |
                  translate }}
                </a>
              </li>

              <li class="menu-item" *ngIf="data.showSubscription && !data?.tenant?.subscriptionScreenDisabled"
                (click)="toggleMenu()">
                <div class="menu-item-icon">
                  <span class="material-symbols-outlined">
                    card_membership
                  </span>
                </div>
                <a class="menu-item-link" routerLink="/subscription" routerLinkActive="active">{{ "menu.subscription" |
                  translate
                  }}</a>
              </li>

              @if(isPageBuilderVisible(data)) {
              <li class="menu-item" (click)="toggleMenu()">
                <div class="menu-item-icon">
                  <span class="material-symbols-outlined">
                    sticky_note_2
                  </span>
                </div>
                <a class="menu-item-link" routerLink="/page-builder/site-pages" routerLinkActive="active">
                  Page Builder
                </a>
              </li>
              }
              @if(isAnalyticsVisible(data)) {
              <li class="menu-item" (click)="toggleMenu()">
                <div class="menu-item-icon">
                  <span class="material-symbols-outlined">
                    sticky_note_2
                  </span>
                </div>
                <a class="menu-item-link" routerLink="/analytics" routerLinkActive="active">
                  Analytics
                </a>
              </li>
              }

              <!-- menu-lg-view -->
              <!-- <li class="menu-item">
                <div class="menu-item-link  d-flex align-items-center" [matMenuTriggerFor]="menuItemTwo"
                  #trigger="matMenuTrigger">
                  <span>Features</span>
                  <svg class="expand-icon" [ngClass]="{ 'rotated': trigger.menuOpen }">
                    <use xlink:href="#arrowDownWhite"></use>
                  </svg>
                </div>
                <mat-menu #menuItemTwo="matMenu" class="dropdown-menu">

                  @if ((isCommunityVisible(data))) {
                  <a class="drop-menu-item" mat-menu-item target="_blank">{{
                    "community.common.community" |
                    translate }}
                  </a>
                  }


                </mat-menu>
              </li> -->
              <!-- menu-lg-view-end -->


              <li class="menu-item" *ngIf="!data?.tenant?.contactPageDisabled" (click)="toggleMenu()">
                <div class="menu-item-icon">
                  <span class="material-symbols-outlined">
                    contact_support
                  </span>
                </div>
                <a class="menu-item-link" routerLink="/contact" routerLinkActive="active">{{
                  "menu.contact" | translate }}</a>
              </li>

              <ng-container *denyRole="['ADMIN']">
                <ng-container *ngIf="!data?.tenant?.newsletter?.newsletterFormDisabled">
                  <li class="menu-item" (click)="toggleMenu()">
                    <div class="menu-item-icon">
                      <span class="material-symbols-outlined">
                        mail
                      </span>
                    </div>
                    <span class="menu-item-link" (click)="showNewsletterPopup()" *allowPlan="'basic'">{{
                      "menu.newsLetter" | translate }}</span>
                  </li>
                </ng-container>
              </ng-container>

              <li class="menu-item" *ngIf="isAdminMenuAllowed(data)" (click)="toggleMenu()">
                <div class="menu-item-icon">
                  <span class="material-symbols-outlined">
                    admin_panel_settings
                  </span>
                </div>
                <a class="menu-item-link" routerLink="/admin/settings/school-settings" routerLinkActive="active">{{
                  "menu.admin" | translate }}</a>
              </li>

              <!--  These extra menu entries are shown directly on the top menu -->
              @for (menuEntry of firstExtraMenuEntries; track index; let index = $index) {
              <li class="menu-item" (click)="toggleMenu()">
                <a class="menu-item-link" [href]="menuEntry.link"
                  [target]="menuEntry?.targetTabState ? '_blank' : '_self'" routerLinkActive="active">
                  {{menuEntry.title }}
                </a>
              </li>
              }

              <!--  When there is no more space on the top menu, we show the rest of the entries in a More dropdown -->
              @if(moreMenuEntries.length > 0) {
              <li class="menu-item">
                <div class="menu-item-link d-flex align-items-center" [matMenuTriggerFor]="menuItemOne"
                  #trigger="matMenuTrigger">
                  <span>{{ "menu.more" | translate }}</span>
                  <svg class="expand-icon" [ngClass]="{ 'rotated': trigger.menuOpen }">
                    <use xlink:href="#arrowDownWhite"></use>
                  </svg>
                </div>
                <mat-menu #menuItemOne="matMenu" class="dropdown-menu">
                  @for (customMenu of moreMenuEntries; track customMenu) {
                  <!-- <a class="drop-menu-item" mat-menu-item (click)="toggleMenu()" [href]="customMenu?.link"
                    target="_blank">
                    <span class="menu-text">{{ customMenu?.title }}</span>
                  </a> -->
                  <a class="drop-menu-item" mat-menu-item (click)="toggleMenu()" [href]="customMenu?.link"
                    [target]="customMenu?.targetTabState ? '_blank' : '_self'">
                    <span class="menu-text">{{ customMenu?.title }}</span>
                  </a>
                  }
                </mat-menu>
              </li>
              }

              <!--  Custom Menu end -->


              <!-- Student Settings Start -->
              <ng-container *ngIf="data?.isLoggedIn">
                <li class="menu-item" *denyRole="['ADMIN']">
                  <div class="menu-item-link  d-flex align-items-center" [matMenuTriggerFor]="menuItemTwo"
                    #trigger="matMenuTrigger">
                    <span>{{ "menu.settings" | translate }}</span>
                    <svg class="expand-icon" [ngClass]="{ 'rotated': trigger.menuOpen }">
                      <use xlink:href="#arrowDownWhite"></use>
                    </svg>
                  </div>
                  <mat-menu #menuItemTwo="matMenu" class="dropdown-menu">

                    <a mat-menu-item class="drop-menu-item" (click)="toggleMenu()"
                      routerLink="/user-settings/my-account">
                      <span class="menu-text">{{ "menu.myAccount" | translate }}</span>
                    </a>

                    <a mat-menu-item class="drop-menu-item" *ngIf="data?.isTeamManager" (click)="toggleMenu()"
                      routerLink="/admin/edit-team">
                      <span class="menu-text">{{ "menu.teamManagement" | translate }}</span>
                    </a>

                    <a mat-menu-item class="drop-menu-item" *ngIf="!data?.tenant?.disableMyCertificatesScreen"
                      (click)="toggleMenu()" routerLink="/user-settings/my-certificates">
                      <span class="menu-text">{{ "menu.myCertificates" | translate }}</span>
                    </a>

                    <a mat-menu-item class="drop-menu-item" *ngIf="data?.tenant?.affiliateProgramSettings?.enabled"
                      (click)="toggleMenu()" routerLink="/user-settings/my-affiliate-program">
                      <span class="menu-text">{{('menu.joinAffiliateProgram' | translate )}}</span>
                    </a>

                    <a mat-menu-item class="drop-menu-item"
                      *ngIf="!data?.tenant?.platformLabelling?.disableCreateYourCourseStudentMenu"
                      (click)="toggleMenu()" href="https://onlinecoursehost.com" target="_blank">
                      <span class="menu-text">{{('menu.createYourCourse' | translate )}}</span>
                    </a>

                  </mat-menu>
                </li>
              </ng-container>

            </ul>
          </ng-container>
        </ng-container>
      </div>

      <div class="navbar-right" #navBarRightMenu>
        <ul class="auth-btns">
          <li>
            <trial-expiration></trial-expiration>
          </li>

          @if(isUpgradeButtonVisible(data)) {
          <li>
            <button class="upgrade-plan-btn" (click)="onUpgradeBtnClicked(data)">
              <svg>
                <use xlink:href="#upgradeIcon"></use>
              </svg>
              <span>Upgrade</span>
            </button>
          </li>
          }
          <li *allowRole="['ADMIN']">
            <button class="aiButton" (click)="toggleAiChat($event, data?.tenant)">
              <svg>
                <use xlink:href="#magicWandIcon"></use>
              </svg>

            </button>
          </li>

          @if (isCommunityVisible(data)) {

          <li>
            <button #openNotificationsDialogButton class="notify-btn"
              (click)="toggleNotificationDialog(data?.user?.id, data?.user?.unreadNotificationsCounter)">
              <svg>
                <use xlink:href="#bellIcon"></use>
              </svg>
              @if(data?.user?.unreadNotificationsCounter > 0) {
              <span class="counter" [ngStyle]="{
              background: data?.tenant.brandTheme.accentColor
              }">
                {{data?.user?.unreadNotificationsCounter}}
              </span>
              }
            </button>

            @defer(on hover(openNotificationsDialogButton)){
            @if (isOpenNotification) {
            <div class="notifications">
              <notification-dialog [user]="data?.user" (closeNotificationDialog)="closeNotificationDialog()" />
            </div>
            }
            }

          </li>
          }

          @if (data?.isLoggedIn && data?.user?.testUser) {

          <li>
            <div class="test-pill"
              matTooltip="Your user is currently a Stripe test user. All interactions with Stripe like course purchase etc. will be with fake credit cards like 424242. Notice that to perform the initial connection to Stripe, you can't be in Test mode.">
              Test
            </div>
          </li>

          }

          <li *ngIf="data?.isLoggedOut">
            <button class="login-button" id="loginButton" (click)="login(false, data?.tenant)">
              <label>{{ "menu.login" | translate }}</label>
              <svg>
                <use xlink:href="#loginIcon"></use>
              </svg>
            </button>
          </li>

          <li *ngIf="data?.pictureUrl">
            <img class="user-avatar" [src]="data?.pictureUrl" />
          </li>

          <li *ngIf="data?.isLoggedIn">
            <button class="logout-button" (click)="logout(data?.tenant)">
              <svg>
                <use xlink:href="#logOutIcon"></use>
              </svg>

            </button>
          </li>

        </ul>
      </div>
    </div>
  </div>
</ng-container>