<close-icon-svg />
<div class="app-sumo-banner">
    <div class="app-sumo-container">
        <div class="app-sumo-logo">
            <div class="offer-des">
                <div class="icon">
                    <img [src]="owlImgUrl" alt="">
                </div>
                <div class="offer-title">
                    <h3>Exclusive Offer</h3>
                    <p>(your students can’t see this)</p>
                </div>
            </div>
            <div class="offer-tag" [ngStyle]="{'background-image': 'url(' + offerTagImgUrl + ')'}">
                <div class="offer-value">
                    <span class="lg-text">45%</span>
                    <span class="sm-text">off</span>
                </div>
            </div>
        </div>
        <div class="app-sumo-content">
            <div class="content">
                <h3 class="app-sumo-title">Exclusive Offer For Lifetime Customers Only</h3>
                <p class="app-sumo-description">Unlock the Unlimited Plan, <span>Save $588</span> In 1 Year</p>
            </div>
            <div class="offer-count-down" >
                <p class="label">Offer Ends:</p>
                <div class="count">
                    <div class="days countdown-item">
                        <strong>{{days}}</strong>
                        <span>Days</span>
                    </div>
                    <div class="hrs countdown-item"><strong [@animateNumber]="hours">{{hours}}</strong>
                        <span>Hours</span>
                    </div>
                    <div class="min countdown-item"><strong [@animateNumber]="minutes">{{minutes}}</strong>
                        <span>Min.</span>
                    </div>
                    <div class="sec countdown-item" ><strong [@animateNumber]="seconds">{{seconds}}</strong>
                        <span>Sec.</span>
                    </div>
                </div>
            </div>
            <a class="app-sumo-button" routerLink="/upgrade-to-unlimited">
                <span>Learn More</span>
            </a>

        </div>
    </div>

    <button class="btn-close" (click)="closeBanner()">
        <svg>
            <use xlink:href="#closeIcon"></use>
        </svg>
    </button>
</div>
